﻿let defaults = {
  newsfeedHolderTarget: ".js-newsfeed",
  maxNews: Number.POSITIVE_INFINITY,
  pathNewsImages: "https://support.viewer.dkplan.niras.dk"
};

let init = async function () {
  
  $(defaults.newsfeedHolderTarget).append(newsfeedHolderTemplate);

  let news = await loadNews();
  let newsItemsHtml = createNewsItems(news, defaults.pathNewsImages, defaults.maxNews);
  $(defaults.newsfeedHolderTarget).append(newsItemsHtml);

  correctNewsItemHeaderColorHeight(defaults.newsfeedHolderTarget);

  addEvents();

};

let loadNews = async function () {
  let result = await DKplanApp.API.newsfeed.getNewsFeeds();

  return result;
};

let newsfeedHolderTemplate = `
<div class="newsFeedHolder newsFeedHolder--is-intro">
    <div class="img-wrapper">
      <div class="img">
          <div>N</div>
      </div>
    </div>
    <div class="text">
      <div class="header" style="padding-left: 0"><b>Nyheder</b></div>
      <p>Find de seneste nyheder fra DKplan herunder. Her leveres nyheder om driftsinformation, inspiration og ny funktionalitet mv.</p>
    </div>
</div>`;

let replacePathToNewsImages = function (content, pathNewsImages) {
  let temp = $('<div>').html(content);

  temp.find("img").each(function () {
    var text = $(this).attr("src");
    text = pathNewsImages + text;
    $(this).attr("src", text);
  });

  return temp.html();
};

let createNewsItems = function (news, pathNewsImages, maxNewsCount) {

  let newsItemsHtml = "";
  let actualNewsCount = 0;

  news
    .filter(item => (new Date(item.startDato) < Date.now() && new Date(item.slutDato) > Date.now()))
    .forEach(function (item, index) {

      item.indhold = replacePathToNewsImages(item.indhold, pathNewsImages);

      if (actualNewsCount < maxNewsCount) {
        let newsItemHtml = `
        <div class="newsFeedHolder holder-${item.type.toLowerCase()}" data-newsitem-id="${index}">
          <div class="dato">${item.startDato.substring(0, 10)}</div>
          <div class="type">${item.type}</div>
          <div class="header-body-holder">
            <div class="header">
              <span class="type-indicator type-indicator-${item.type.toLowerCase()}"></span>${item.overskrift}
            </div>
            <div class="body">${item.resume}</div>
          </div>
          <div class="show-the-news" style="display:none; padding:24px; font-size: 0.8rem;">
              ${item.indhold}
          </div>
          <div class="read-the-news" data-newsitem-id="${index}" style="cursor: pointer; text-align: right; color:blue; font-size: 0.8rem;">Læs mere</div>
        </div>`;
     

        newsItemsHtml += newsItemHtml;
      
        actualNewsCount++;
      }
    });

  return newsItemsHtml;

};

let correctNewsItemHeaderColorHeight = function (container) {

  $(container).find(".header-body-holder").each(function (index, elm) {
    let indicatorHeight = $(elm).height() + 26;
    $(elm).find(".type-indicator").css("height", indicatorHeight);
  });
}

let addEvents = function () {
  $(".read-the-news").on("click", function (evt) {

    let itemButton = $(this)[0];

    let id = itemButton.dataset.newsitemId;

    let item = $(`.newsFeedHolder[data-newsitem-id='${id}']`).find(".show-the-news");
    
    item.slideToggle('slow', function () {
      if ($(item).is(':visible')) {
        $(itemButton).text('Luk nyheden');
      } else {
        $(itemButton).text('Læs mere');
      }
    });
  });
};


export default {
  init
}