﻿(function (exports, API) {


    exports.DKplanApp = exports.DKplanApp || {};

    var app = exports.DKplanApp;


    var defaults = {
        host: undefined,
        kunde: undefined
    }
    

    var init = function (config) {

        for (var prop in config) {
            if (config.hasOwnProperty(prop)) {
                defaults[prop] = config[prop];
            }
        }

    }

  var getNewsFeeds = function () {
      return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewsfeeds", {
        method: "GET",
        data: {

        }
    });
    }

    var getNewsItem = function (newsId) {
        return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewsItem", {
            method: "GET",
            data: {
                cmsId : newsId
            }
        });
    }

    var getNumberOfNewNewsItem = function (newsId) {
        return API.call(defaults.host + defaults.kunde + "/newsfeed/GetNewNewsitems", {
            method: "GET",
            data: {
               
            }
        });
    }

    app.API = app.API || {};

    app.API.newsfeed = app.API.newsfeed || {
        init: init,
        getNewsFeeds : getNewsFeeds,
        getNewsItem: getNewsItem,
        getNumberOfNewNewsItem: getNumberOfNewNewsItem
    };

})(window, DKplanApp.API.base);